import { ref, Ref, reactive, onBeforeMount} from 'vue'
import Api from '@/http/modules/index'
let {
  // 获取下拉
  'POST/financial_banks/drop_list': dropOptionApi,
} = Api


interface DropOptionItem {
  label: string;
  id: string;
}

interface DropOption {
  [prop: string]: DropOptionItem[];
  accountNumber: DropOptionItem[];
  accountName: DropOptionItem[];
  bankName: DropOptionItem[];
  company: DropOptionItem[];
  currency: DropOptionItem[];
}

let defDropBox: any = { "accountNumber": "", "accountName": [], "bankName": "",  "currency": "", "costFrom": [] }
const dropObj: DropOption = {
  "accountNumber": [],// 账户ID
  "accountName": [],// 账户简称
  "bankName": [],// 银行简称
  "currency": [],// 币种
  "company": [],// 公司名称
}


// export const getDropBoxHandle = async (optionsObj = defDropBox) => {
//  const dropResult: any[] = await dropOptionApi(optionsObj)
export const getDropBoxHandle = async (optionsObj = {}) => {
  const dropResult: any[] = await dropOptionApi(Object.assign({ "accountNumber": "", "accountName": [], "bankName": "",  "currency": "", "costFrom": [] } as any, optionsObj))
  // 清空
  for (const key in dropObj) {
    dropObj[key] = []
  }
  // 逐步添加下拉框选项
  for (const item of dropResult) {
    item.accountNumber && dropObj.accountNumber.push(item.accountNumber)
    item.accountName && dropObj.accountName.push(item.accountName)
    item.bankName && dropObj.bankName.push(item.bankName)
    item.company && dropObj.company.push(item.company)
    item.currency && dropObj.currency.push(item.currency)
  }
  // 去重
  for (const key in dropObj) {
    dropObj[key] = Array.from(new Set(dropObj[key])).map((e: any) => ({label: e, id: e}))
  }

  return dropObj
}